<template>
  <div class="productDetails-container">
    <div class="productImg">
      <div class="header2">
        <van-nav-bar title="提报产品信息" left-text="返回列表" left-arrow @click-left="onClickLeft"></van-nav-bar>
      </div>
      <div class="title_product" style="word-break:break-all;" v-html="html_decode(auditProductList.productName)">
      </div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in auditProductList.imgs" :key='index'>
          <van-image width="100%" height="100%" @click="showImg" :src="item||require('../../assets/img/img.png')" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="productDescribe">
      <van-cell-group class="audit_cs1">
        <van-cell title="产品类目:" class="myCell" :value="auditProductList.commodityContractTypeName" />
      </van-cell-group>
      <van-cell-group v-if="showName">
        <van-cell title="产品经理:" class="myCell" :value="auditProductList.UName" />
      </van-cell-group>
      <van-cell-group>
        <van-cell title="是否需要外输:" class="myCell" :value="auditProductList.outputName" />
      </van-cell-group>
      <div class="auditProduct_cs">
        <div class="auditProduct_chlid">
          需要外输渠道:
          <div class="auditProduct_value">
            <span v-for="(item,index) in auditProductList.outputTypeName" :key="index" class="pdimary_cs">
              <van-tag round type="primary">{{item}}</van-tag>
            </span>
          </div>
        </div>
      </div>
      <van-cell-group>
        <van-cell title="卖点:" class="myCell" :value="auditProductList.description || '-'">
          <template #default>
            <span v-html="html_decode(auditProductList.description)"></span>
          </template>
        </van-cell>
      </van-cell-group>
      <div style="padding: 70px"></div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="todo-list">基础信息</van-tabbar-item>
      <van-tabbar-item @click="auditSpecificationMessage" icon="column">套餐信息</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { Notify } from 'vant'
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      showName: true,
      imgList: [],
      auditProductList: {
        imgs: [], //轮播图列表
        productName: '', //商品名称
        commodityContractTypeName: '', //产品类目
        outputName: '', //是否需要外输
        outputTypeName: [], //需要外输渠道列表
        id: '',
        UName: '',
        description: '',
      }
    }
  },
  created() {
    Toast.loading({
      message: '加载中...',
      overlay: true,
      duration: 300,
      forbidClick: true,
    });
    setTimeout(()=>{
        this.auditProductData()
    },100)
  },
  methods: {
      // 识别后台传递过来的转义符部分无法识别
      html_decode(str) {
        if(str == undefined || str == ''){
          return '-';
        }else{
          var s = ''
          if (str.length == 0) return ''
          s = str.replace(/&amp;/g, '&')
          s = s.replace(/&lt;/g, '<')
          s = s.replace(/&gt;/g, '>')
          s = s.replace(/&nbsp;/g, ' ')
          s = s.replace(/&#39;/g, "'")
          s = s.replace(/&amp;/g, '&')
          s = s.replace(/&quot;/g, '"')
          s = s.replace(/<br\/>/g, '\n')
          return s
        }
    },
    onClickLeft() {
      this.$router.push({
        path: '/productAudit',
        query: {}
      })
    },
    showImg(e) {
      this.imgList.push(e.target.src);
      ImagePreview(this.imgList);
      this.imgList = [];
    },
    auditSpecificationMessage() {
      this.$router.push({
        path: '/showAuditSpecificationMessage',
        query: {
          id: this.id,
          ids: this.$route.query.id
        }
      })
    },
    auditProductData() {
      this.$http
        .ajax(
          '/ffkj-main/merchantProduct/details',
          {
            id: this.$route.query.id
          },
          'get',
          {}
        )
        .then(res => {
          if (res.code == 0) {
            this.auditProductList.imgs = res.data.imgs
            this.auditProductList.productName = res.data.productName
            this.auditProductList.commodityContractTypeName = res.data.commodityContractTypeName
            this.auditProductList.outputName = res.data.outputName
            this.auditProductList.outputTypeName = res.data.outputTypeName
            this.id = res.data.id
            this.auditProductList.description = res.data.description;
            this.auditProductList.UName = res.data.name
            if (res.data.name == '' || res.data.name == undefined || res.data.name == null) {
              this.showName = false
            } else {
              this.showName = true
            }
          } else {
            Notify({ type: 'danger', message: res.message })
          }
        })
    }
  }
}
</script>

<style scoped>
</style>